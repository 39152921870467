import styled, { css } from 'styled-components';
import Modal from '../../styles/Components/Modal/ModalStyles';
import { buttonAnimations, buttonSize, buttonVariant } from './buttonHierarchy';

export const Cta = styled.div`
  cursor: pointer;
  ${props =>
    props?.variant === 'tertiary' &&
    css`
      min-width: 142px;
    `}
  ${props =>
    !props?.linkClickable &&
    (props?.variant !== 'linkColor' || props?.variant !== 'linkGray')
      ? buttonAnimations.scaleBounce.base
      : null}
  &:hover {
    ${props =>
      !props?.linkClickable &&
      (props.variant !== 'linkColor' || props.variant !== 'linkGray')
        ? buttonAnimations.scaleBounce.hover
        : null}
  }

  && a,
  && > div {
    display: inline-flex;
    flex-flow: ${props => (props.reverse ? 'row-reverse' : 'row')} nowrap;
    align-items: center;
    justify-content: center;
    gap: ${props => (props.size === 'xl' ? '14px' : '9.5px')};
    width: fit-content;
    background-color: ${props => buttonVariant[props?.variant]?.bg};
    color: ${props => buttonVariant[props?.variant]?.text};
    border: 1px solid ${props => buttonVariant[props?.variant]?.border};
    border-radius: 6px;
    ${props => buttonSize[props.size]};
    padding: ${props =>
      (props?.variant === 'linkColor' ||
        props?.variant === 'linkGray' ||
        props?.variant === 'brand') &&
      '0'};
    text-decoration: none;
    ${props =>
      props?.variant === 'linkColor' || props?.variant === 'linkGray'
        ? buttonAnimations.slideIcon.base
        : buttonAnimations.scaleBounce.base}
    cursor: pointer;

    svg {
      width: ${props => (props.size === 'xl' ? '20px' : '16.5px')};
    }

    &:has(.app-badge) {
      display: block;
    }

    div {
      display: inherit;
      flex-flow: inherit;
      align-items: inherit;
      justify-content: inherit;
      gap: inherit;
    }

    &:hover {
      background-color: ${props => buttonVariant[props?.variant]?.hoverBg};
      ${props =>
        props.variant === 'linkColor' || props.variant === 'linkGray'
          ? buttonAnimations.slideIcon.hover
          : buttonAnimations.scaleBounce.hover}
    }

    &:focus {
      border-color: ${props => buttonVariant[props?.variant]?.focusedBorder};
    }

    ${props =>
      props.disabled &&
      css`
        background-color: ${buttonVariant[props?.variant]?.disabledBg};
        color: ${buttonVariant[props?.variant]?.disabledText};
        border-color: ${buttonVariant[props?.variant]?.disabledBorder};
        cursor: not-allowed;
      `};
  }

  .cta {
    ${props => !props?.linkClickable && 'pointer-events: none;'}
  }
`;

export const ModalCTAContainer = styled.div`
  display: contents;

  ${Modal} {
    display: contents;
  }
`;
