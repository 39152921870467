import styled from 'styled-components';
import { atMinWidth } from '../../../styles/atoms/breakpoints';

export default styled.button`
  width: 25px;
  height: 25px;
  border: none;
  outline: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 32px;
  ${atMinWidth.xl`;
    display: none;
  `}
  svg {
    width: 25px;
    height: 25px;
    rect {
      transition: all 350ms ease-in-out;
    }
  }
  &.active svg rect:first-child {
    transform: rotate(45deg) translate(3px, -4px);
  }
  &.active svg rect:nth-child(2) {
    transform: translate(-30px, 8px);
  }
  &.active svg rect:last-child {
    transform: rotate(-45deg) translate(-10.5px, 15px);
  }
`;
