import { graphql, useStaticQuery } from 'gatsby';
import React, { forwardRef } from 'react';
import FooterContact from './components/FooterContact';
import FooterMenu from './components/FooterMenu';
import FooterPrivacy from './components/FooterPrivacy';
import { Column, Footer, Wrapper } from './styles/Footer.styled';

const FooterComponent = forwardRef(
  ({ component, hideMenu = false, disclaimer }, ref) => {
    const { datoCmsFooterComponent } = useStaticQuery(staticFooterQuery);
    const footerData = component || datoCmsFooterComponent;
    const { contact, footerMenu, privacy, disclaimerList } = footerData;

    return (
      <>
        <Footer ref={ref} className={hideMenu ? 'menu-hidden' : ''}>
          {!hideMenu && (contact || footerMenu) && (
            <Wrapper>
              <Column>{contact && <FooterContact {...contact} />}</Column>
              <Column>{footerMenu && <FooterMenu {...footerMenu} />}</Column>
            </Wrapper>
          )}
          {(privacy || disclaimerList) && (
            <FooterPrivacy
              privacy={privacy}
              disclaimerList={
                disclaimer
                  ? [
                      {
                        disclaimerTitle: 'Disclaimer',
                        disclaimerText: disclaimer,
                      },
                    ]
                  : disclaimerList?.blocks
              }
            />
          )}
        </Footer>
      </>
    );
  }
);

export default FooterComponent;

const staticFooterQuery = graphql`
  query StaticFooterQuery {
    datoCmsFooterComponent(id: { eq: "DatoCmsFooterComponent-24396764-en" }) {
      ...datoCmsFooterComponent
    }
  }
`;
