export const getTag = value => {
  switch (value) {
    case 'Heading 1':
      return 'h1';

    case 'Heading 2':
      return 'h2';

    case 'Heading 3':
      return 'h3';

    case 'Heading 4':
      return 'h4';

    case 'Heading 5':
      return 'h5';

    case 'Heading 6':
      return 'h6';

    case 'Paragraph':
    default:
      return 'p';
  }
};
