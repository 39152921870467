import styled from 'styled-components';

import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';
import { atMinWidth } from '../../../styles/atoms/breakpoints';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  ${atMinWidth.lg`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`}
`;
export const Group = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  overflow: hidden;
`;
export const Column = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const Title = styled.p`
  ${font('text', 'md', '700')}
  color: ${colors.base.white};
`;
export const List = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  list-style: none;
  padding: 0px;
  margin: 0px;
`;
export const ListItem = styled.li`
    width: 100%;
    .footer-link {
        display: block;
        width: 100%;
        ${font('text', 'sm', '400')}
        color: ${colors.gray[100]};
        transition: color 0.3s ease;
        &:hover {
            color: ${colors.gray[500]};
            text-decoration: none;
        }
    }
`;
