import React from 'react';

import FooterSocial from './FooterSocial';

import Link from '../../../utils/Link';
import { setMenuData } from '../utils/helpers';

import {
  Container,
  Group,
  Column,
  Title,
  List,
  ListItem,
} from '../styles/FooterMenu.styled';

const FooterMenu = ({ menuItem }) => {
  const menuData = setMenuData(menuItem);
  return (
    <Container>
      {menuData?.map(({ id, items }) => (
        <Group key={id}>
          {items.map(item => (
            <Column key={item?.internalName}>
              <Title>{item?.label}</Title>
              <List>
                {item?.childMenuItem?.map(({ menuItemCallToAction }) => (
                  <ListItem key={menuItemCallToAction?.url}>
                    <Link
                      className="footer-link"
                      to={menuItemCallToAction?.url}
                      openInNewTab={menuItemCallToAction?.openInNewTab}
                    >
                      {menuItemCallToAction?.label}
                    </Link>
                  </ListItem>
                ))}
              </List>
              {item.internalName.includes('Company') && <FooterSocial />}
            </Column>
          ))}
        </Group>
      ))}
    </Container>
  );
};

export default FooterMenu;
