import styled, { css } from 'styled-components';

import { atMinWidth } from '../../../styles/atoms/breakpoints';

const defaultLeft = css`
  width: 35vw;
  height: 35vw;
  left: -100px;
  top: -100px;
  min-width: 180px;
  min-height: 180px;
`;

const defaultRight = css`
  width: 40vw;
  height: 40vw;
  right: -75px;
  bottom: 0px;
  min-width: 200px;
  min-height: 200px;

  ${atMinWidth.lg`
    bottom: unset;
    top: 85px;
  `}
`;

const wideLeft = css`
  width: 60vw;
  height: 60vw;
  right: 85vw;
  bottom: 85%;
`;

const wideRight = css`
  width: 60vw;
  height: 60vw;
  left: 80vw;
  top: 30%;
`;

const hexPositions = {
  default: {
    left: defaultLeft,
    right: defaultRight,
  },
  wide: {
    left: wideLeft,
    right: wideRight,
  },
};

export const Hex = styled.svg`
  position: absolute;
  opacity: 0.05;
  mix-blend-mode: ${props =>
    props.theme === 'dark' ? 'color-dodge' : 'color-burn'};
  z-index: -1;

  ${props => hexPositions[props.variant][props.position]}
`;

export const HexWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow-x: clip;
  overflow-y: ${props => (props.showOverflow ? 'visible' : 'hidden')};
`;

export const Wave = styled.svg`
  position: absolute;
  right: 0;
  top: 0;
`;

export const WaveWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: clip;
`;
