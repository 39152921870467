import React from 'react';

import Link from '../../../utils/Link';

import { Container } from '../styles/FooterSocial.styled';

import Icon from '../../../styles/atoms/icons';

const FooterSocial = () => (
  <Container>
    <p className="heading">Follow us!</p>
    <div className="icons">
      <Link
        className="social-link"
        openInNewTab
        to="https://www.facebook.com/shopmonkey.io"
      >
        <Icon id="GrFacebook" />
      </Link>
      <Link
        className="social-link"
        openInNewTab
        to="https://www.instagram.com/shopmonkey.io/"
      >
        <Icon id="GrInstagram" />
      </Link>
      <Link
        className="social-link"
        openInNewTab
        to="https://www.linkedin.com/company/shopmonkey/"
      >
        <Icon id="GrLinkedin" />
      </Link>
      <Link
        className="social-link"
        openInNewTab
        to="https://www.youtube.com/channel/UCfhEiNGzSujAeu6tyHgplbA"
      >
        <Icon id="GrYoutube" />
      </Link>
    </div>
  </Container>
);

export default FooterSocial;
