import styled from 'styled-components';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';

export const Footer = styled.footer`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 64px;
    background: ${colors.gray[900]};
    padding: 48px 0px;

    ${atMinWidth.sm`
        padding: 56px 0px;
    `}

    ${atMinWidth.md`
        padding: 72px 0px 33px;
    `}

    ${atMinWidth.xl`
        padding: 96px 0px 33px;
        gap: 82px;
    `}

    &.menu-hidden {
      ${atMinWidth.md`
          padding-top: 33px;
      `}
    }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 64px;
  max-width: 840px;
  padding: 0px 23.5px;
  margin: 0px auto;

  ${atMinWidth.sm`
    padding: 0px 35px;
  `}

  ${atMinWidth.xl`
    max-width: 1240px;
    display: grid;
    grid-template-columns: 301px 1fr;
    gap: 100px;
  `}
`;

export const Column = styled.div`
  width: 100%;
  height: 100%;
`;
