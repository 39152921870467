import styled from 'styled-components';
import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';

export default styled.li`
  width: 100%;
  button,
  .nav-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    background: none;
    text-align: left;
    padding: 16px;
    border-bottom: 1px solid ${colors.gray[100]};
    ${font('overline', 'sm', '700')}
    text-decoration: none;
    color: ${colors.gray[900]};
    transition: all 300ms ease-in-out;
    .carrot-icon {
      transition: all 250ms ease-in-out;
      width: 10px;
      height: 7px;
    }
    &.active,
    &:hover {
      background: ${colors.base.heroSurface};
      color: ${colors.primary[500]};
      text-decoration: none;
    }
    &.active {
      .carrot-icon {
        transform: rotate(180deg);
      }
    }
  }
`;
