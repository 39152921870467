const sortMenuItems = array => {
  if (!array) return;
  const newArray = [...array];
  const pricingIndex = newArray.findIndex(
    item => item.internalName === 'Pricing'
  );
  if (pricingIndex > -1) {
    const pricingItem = newArray.splice(pricingIndex, 1);
    newArray.unshift(pricingItem[0]);
  }
  const grayBackgrounds = ['Tools', 'Featured Resource'];
  const updatedArray = newArray?.map(item => {
    item?.childMenuItem?.map(i => {
      if (grayBackgrounds.includes(i.internalName)) {
        i.background = '#F9FBFE';
      }
      return i;
    });
    return item;
  });
  updatedArray.pop();
  return updatedArray;
};

export default sortMenuItems;
