import styled from 'styled-components';

import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';
import { atMinWidth } from '../../../styles/atoms/breakpoints';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${atMinWidth.xl`
  gap: 48px;
`}
`;
export const Icon = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
export const Text = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .heading {
        ${font('display', 'sm', '700')}
        color: ${colors.base.white};
        ${atMinWidth.sm`
            ${font('display', 'md', '700')}
        `}
    }
    .subhead {
        ${font('text', 'sm', '400')}
        color: ${colors.base.white};
        ${atMinWidth.sm`
            ${font('text', 'md', '400')}
        `}
    }
`;
export const List = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0px;
  margin: 0px;
  list-style: none;
`;
export const ListItem = styled.li`
    width: 100%;
    display: flex;
    gap: 16px;
    .contact-link {
        display: flex;
        align-items: center;
        gap: 16px;
    }
    .icon {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 50%;
    }
    .text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0px;
        .heading {
            ${font('text', 'sm', '400')}
            color: ${colors.base.white};
        }
        .subhead, .subhead p, .subhead a {
            ${font('display', 'sm', '400')}
            color: ${colors.base.white};
        }
    }
`;
