import useMediaQuery from '@material-ui/core/useMediaQuery';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { breakpoints } from '../styles/atoms/breakpoints';

const OptimizedImage = ({
  src,
  image,
  msrc,
  mimage,
  timage,
  alt,
  title,
  loading,
  className,
  width,
  height,
}) => {
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.lg})`);
  const isTablet = useMediaQuery(`(min-width: ${breakpoints.md})`);

  if (image) {
    let im;

    if (isDesktop) {
      im = image;
    } else if (isTablet && timage) {
      im = timage;
    } else if (mimage) {
      im = mimage;
    } else {
      im = image;
    }

    return (
      <GatsbyImage
        alt={alt || title || ''}
        loading={loading || 'lazy'}
        image={im || image}
        className={className}
      />
    );
  }

  return (
    <img
      src={!isDesktop && msrc ? msrc : src}
      alt={alt || title || ''}
      className={className}
      loading={loading || 'lazy'}
      width={width || '100%'}
      height={height || '100%'}
    />
  );
};

export default OptimizedImage;
