import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import {
  DropdownStyles,
  NavItemStyles,
  NavSectionGridStyles,
} from '../../styles/Components/Header/DropdownStyles';
import { colors } from '../../styles/atoms/colors';
import Icon from '../../styles/atoms/icons';
import Link from '../../utils/Link';
import Img from '../../utils/OptimizedImage';

const MenuImage = ({ src, className }) => (
  <div className={className}>
    <figure>
      {src?.image?.gatsbyImageData ? (
        <Img
          image={src?.image?.gatsbyImageData}
          alt={src?.internalName}
          title={src?.image?.title}
        />
      ) : (
        <Img
          src={src?.image?.url}
          alt={src?.internalName}
          title={src?.image?.title}
        />
      )}
    </figure>
  </div>
);
const NavItem = ({ innerItem, className, position }) => (
  <NavItemStyles hoverColor={innerItem?.hoverColor?.hex} className={className}>
    <Link to={innerItem?.menuItemCallToAction?.url}>
      <Nav.Item>
        {(innerItem?.iconImage ||
          (position !== 'bottom' && innerItem?.fullImage)) && (
          <MenuImage
            src={innerItem.fullImage || innerItem.iconImage}
            className={innerItem?.fullImage ? 'full-image' : ''}
          />
        )}
        <div
          className={
            innerItem?.fullImage || position === 'bottom'
              ? 'article-content'
              : ''
          }
        >
          {innerItem?.fullImage && (
            <figure className="full-image">
              <Img
                src={innerItem.fullImage.image.url}
                alt={innerItem.fullImage.internalName}
                title={innerItem.fullImage.image.title}
              />
            </figure>
          )}
          <div className="title-content">
            <div className="title">
              {innerItem.label}
              {position !== 'bottom' && (
                <span className="title-icon">
                  <Icon id="FiArrowLeft" />
                </span>
              )}
            </div>
            <div className="subhead-wrapper">
              <div
                className={`subhead ${
                  position === 'bottom' ? 'link-text' : ''
                }`}
                dangerouslySetInnerHTML={{
                  __html: innerItem.subhead,
                }}
              />
              {position === 'bottom' && (
                <Icon id="FiChevronRight" color={colors.gray[600]} />
              )}
            </div>
          </div>
        </div>
      </Nav.Item>
    </Link>
  </NavItemStyles>
);
const NavSection = ({
  className,
  count,
  featured,
  heading,
  isTop,
  subitem,
  topLink,
  type,
}) => (
  <div className={`nav-section ${className || ''}`}>
    {isTop && (
      <div className="top-block">
        <p>{heading}</p>
        {topLink && (
          <Link to={topLink} className="d-none">
            View All
          </Link>
        )}
      </div>
    )}
    <NavSectionGridStyles count={count ?? subitem.length} featured={featured}>
      {subitem.map(item => (
        <NavItem
          innerItem={item}
          key={item.internalName}
          className={type === 'article' ? 'mb-8' : ''}
          position={type === 'article' ? 'bottom' : ''}
        />
      ))}
    </NavSectionGridStyles>
  </div>
);
const DropdownContent = ({ item, nowrap }) => (
  <Container className="justify-content-start px-0">
    <DropdownStyles>
      <Row className={`${nowrap ? 'no-wrap' : ''} mx-0`}>
        {item.childMenuItem[0].childMenuItem.length === 0 &&
          item.childMenuItem.map(subitem => (
            <Col key={subitem.internalName}>
              {subitem?.menuItemCallToAction && (
                <Link to={subitem.menuItemCallToAction.url}>
                  <Nav.Item>
                    <div className="title">{subitem.label}</div>
                    <div className="subhead">{subitem.subhead}</div>
                  </Nav.Item>
                </Link>
              )}
            </Col>
          ))}
        {item.childMenuItem[0].childMenuItem.length > 0 &&
          item.childMenuItem
            .slice(0, item.childMenuItem.length - 1)
            .map(subitem => (
              <NavSection
                key={subitem.internalName}
                isTop
                heading={subitem.label}
                subitem={subitem.childMenuItem.filter(
                  childItem => childItem.label.length > 2
                )}
                count={subitem.maxDropdownColumnItems}
              />
            ))}
        <NavSection
          key={item.childMenuItem[item.childMenuItem.length - 1].internalName}
          isTop
          heading={item.childMenuItem[item.childMenuItem.length - 1].label}
          subitem={item.childMenuItem[
            item.childMenuItem.length - 1
          ].childMenuItem.filter(childItem => childItem.label.length > 2)}
          count={
            item.childMenuItem[item.childMenuItem.length - 1]
              .maxDropdownColumnItems
          }
          className={
            item.label !== 'Company' && item.label !== 'Resources'
              ? 'bg-surface'
              : ''
          }
        />
      </Row>
      {item?.featuredMenuItem && (
        <div className="story-wrapper">
          <NavSection
            count={item?.maxDropdownColumnItems}
            featured
            heading={item?.featuredMenuItem?.label}
            isTop
            key={item?.id}
            subitem={item?.featuredMenuItem?.childMenuItem}
            topLink="https://www.shopmonkey.io/case-studies"
            type="article"
          />
        </div>
      )}
    </DropdownStyles>
  </Container>
);

export default DropdownContent;
