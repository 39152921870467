import styled from 'styled-components';

import Image from '../../utils/OptimizedImage';

import { atMinWidth } from '../../styles/atoms/breakpoints';
import { colors } from '../../styles/atoms/colors';
import { padding, paddingY } from '../../styles/atoms/spacing';

export const BackgroundImage = styled(Image)`
  .form-only {
    height: 50%;
    width: 50%;
    top: 0;
    object-fit: contain;
    .img {
      width: 100%;
      height: 50%;
      object-fit: contain;
    }
  }
`;

export const BackgroundImageFormOnly = styled.div`
  position: absolute;
  width: 100%;
  height: 50%;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 80%;
    height: 100%;
    img {
      object-fit: contain !important;
    }
  }
`;

export const SectionStyle = styled.section`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  background-color: ${props => props.bgColor || colors.base.light};
  z-index: 1;

  ${props => props.customStyle}
`;

export const InnerWrapper = styled.div`
  display: block;
  width: 100%;
  ${padding(23.5, 48)}
  z-index: 1;

  ${atMinWidth.sm`
    ${padding(56, 35)}
  `}

  ${atMinWidth.md`
    ${paddingY(72)}
    max-width: 840px;
  `}

  ${atMinWidth.lg`
    ${padding(23.5, 80)}
    max-width: 1007px; 
  `}

  ${atMinWidth.xl`
    max-width: 1218px;
  `}
  
  ${props => props.customStyle}

  & > img, & > .gatsby-image-wrapper {
    position: absolute;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: -1;
  }
`;
