import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';

import { BackgroundImage } from '../Section.styles';

/**
 *
 * @param {string} backgroundName options: ['light', 'dark-neon']
 * @returns
 */
const SectionBg = ({ backgroundName }) => {
  const { backgrounds } = useStaticQuery(backgroundQuery);

  const background = backgrounds.edges.filter(image =>
    image?.node?.name.includes(`bg-${backgroundName}`)
  );

  const desktop = background.find(image =>
    image?.node?.name?.includes('desktop')
  )?.node?.image?.imageData;
  const tablet = background.find(image => image?.node?.name?.includes('tablet'))
    ?.node?.image?.imageData;
  const mobile = background.find(image => image?.node?.name?.includes('mobile'))
    ?.node?.image?.imageData;

  return <BackgroundImage image={desktop} timage={tablet} mimage={mobile} />;
};

export default SectionBg;

const backgroundQuery = graphql`
  {
    backgrounds: allFile(filter: { relativeDirectory: { eq: "background" } }) {
      edges {
        node {
          name
          image: childImageSharp {
            imageData: gatsbyImageData
          }
        }
      }
    }
  }
`;
