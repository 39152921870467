import { css } from 'styled-components';

export const fontSize = {
  text: {
    xs: { fontSize: '0.75', lineHeight: '140', letterSpacing: '0.26' },
    sm: { fontSize: '0.9', lineHeight: '150', letterSpacing: '0' },
    md: { fontSize: '1', lineHeight: '150', letterSpacing: '-0.16' },
    lg: { fontSize: '1.125', lineHeight: '150', letterSpacing: '-0.27' },
    xl: { fontSize: '1.25', lineHeight: '150', letterSpacing: '-0.27' },
  },
  display: {
    xs: { fontSize: '1', lineHeight: '110', letterSpacing: '-0.16' },
    sm: { fontSize: '1.25', lineHeight: '110', letterSpacing: '-0.27' },
    md: { fontSize: '1.563', lineHeight: '120', letterSpacing: '-0.3' },
    lg: { fontSize: '1.953', lineHeight: '110', letterSpacing: '-0.3' },
    xl: { fontSize: '2.441', lineHeight: '110', letterSpacing: '-0.3' },
    xxl: { fontSize: '2.95', lineHeight: '110', letterSpacing: '-0.3' },
  },
  overline: {
    sm: { fontSize: '.667', lineHeight: '120', letterSpacing: '2' },
    md: { fontSize: '.75', lineHeight: '110', letterSpacing: '2' },
  },
};

/*
 *   ${font('display', 'xl', '700')}
 */
export const font = (style, size, weight, isItalic = false) => css`
  font-size: ${fontSize[style][size].fontSize ||
    fontSize[style].md.fontSize}rem;
  font-weight: ${weight || 400};
  font-family: 'CircularXX', sans-serif;
  line-height: ${fontSize[style][size].lineHeight ||
    fontSize[style].md.lineHeight}%;
  letter-spacing: ${fontSize[style][size].letterSpacing ||
    fontSize[style].md.letterSpacing}px;
  margin-bottom: 0;
  font-style: ${isItalic ? 'italic' : 'normal'};
  ${style === 'overline' &&
    css`
      text-transform: uppercase;
    `};
`;

/**
 * MUST BE USED ONLY IF FONT HAS ALREADY BEEN DECLARED
 * @param {sting} style
 * @param {string} size
 * @returns
 */
export const changeFontSize = (style, size) => css`
  font-size: ${fontSize[style][size].fontSize ||
    fontSize[style].md.fontSize}rem;
`;
