import React from 'react';
import { AnimatePresence } from 'framer-motion';
import OptimizedImage from '../../../utils/OptimizedImage';
import Link from '../../../utils/Link';
import {
  Dropdown,
  Menu,
  List,
  ListItem,
  Text,
} from '../styles/MobileDropdown.styled';
import Icon from '../../../styles/atoms/icons';

const showSubheads = [
  'Business Valuation Calculator',
  'Help Center',
  'FAQ',
  'Email Us',
  'Call Us',
];

const MobileDropdown = ({ index, data, activeDropdown }) => (
  <AnimatePresence>
    {activeDropdown === index && (
      <Dropdown
        className={activeDropdown === index ? 'active' : ''}
        key="content"
        initial="collapsed"
        animate="open"
        exit="collapsed"
        variants={{
          open: { opacity: 1, height: 'auto' },
          collapsed: { opacity: 0, height: 0 },
        }}
        transition={{ duration: 0.3 }}
      >
        {[...data?.childMenuItem, data?.featuredMenuItem]?.map(item => (
          <Menu key={item?.internalName} background={item?.background}>
            <p className="menu-heading">{item?.label}</p>
            <List>
              {item?.childMenuItem?.map(link => {
                if (!link?.menuItemCallToAction?.url) return null;
                return (
                  <ListItem key={link?.internalName}>
                    <Link
                      to={link?.menuItemCallToAction?.url}
                      className={
                        link?.fullImage?.image?.url && 'align-items-center'
                      }
                    >
                      {link?.fullImage?.image?.url && (
                        <OptimizedImage
                          className="full-image"
                          src={link?.fullImage?.image?.url}
                          alt={link?.label}
                          title={link?.fullImage?.image?.title}
                          width="100px"
                          height="100px"
                        />
                      )}
                      {link?.iconImage?.image?.url && (
                        <OptimizedImage
                          className="icon"
                          src={link?.iconImage?.image?.url}
                          alt={link?.label}
                          title={link?.iconImage?.image?.title}
                          width="32px"
                          height="32px"
                        />
                      )}
                      <Text
                        showSubhead={showSubheads.includes(link?.internalName)}
                      >
                        <p className="heading">
                          {link?.label}
                          <Icon id="FiArrowLeft" className="arrow-icon" />
                        </p>
                        <div
                          className="subhead"
                          dangerouslySetInnerHTML={{ __html: link?.subhead }}
                        />
                      </Text>
                    </Link>
                  </ListItem>
                );
              })}
            </List>
          </Menu>
        ))}
      </Dropdown>
    )}
  </AnimatePresence>
);

export default MobileDropdown;
