import React from 'react';
import Link from '../../../utils/Link';
import OptimizedImage from '../../../utils/OptimizedImage';
import {
  Container,
  Disclaimers,
  Links,
  Row,
  Text,
} from '../styles/FooterPrivacy.styled';

const FooterPrivacy = ({ privacy, disclaimerList }) => (
  <Container>
    <Row>
      <Text>
        <div className="icon">
          <OptimizedImage
            image={privacy?.icon?.image?.gatsbyImageData}
            src={privacy?.icon?.image?.url}
            alt={privacy?.icon?.internalName}
            title={privacy?.icon?.image?.title}
          />
        </div>
        <p className="heading">
          © {new Date().getFullYear()}, {privacy?.copywrite}
        </p>
      </Text>
      <Links>
        {privacy?.links?.map(link => (
          <Link key={link?.id} to={link?.url} className="privacy-link">
            {link?.label}
          </Link>
        ))}
      </Links>
    </Row>
    {disclaimerList?.length > 0 && (
      <Disclaimers>
        {disclaimerList?.map(({ disclaimerTitle, disclaimerText }) => (
          <p key={disclaimerTitle} className="disclaimer-text">
            {disclaimerText}
          </p>
        ))}
      </Disclaimers>
    )}
  </Container>
);

export default FooterPrivacy;
