import React from 'react';
import { css } from 'styled-components';

import { font } from '../../styles/atoms/typography';
import { colors } from '../../styles/atoms/colors';
import Icon from '../../styles/atoms/icons';

export const buttonVariant = {
  primary: {
    bg: colors.primary[500],
    text: colors.base.white,
    border: colors.primary[500],
    hoverBg: colors.primary[700],
    focusedBorder: colors.primary[700],
    disabledBg: colors.primary[50],
    disabledText: colors.base.white,
    disabledBorder: colors.primary[50],
  },
  secondary: {
    bg: colors.green[600],
    text: colors.base.white,
    border: colors.green[600],
    hoverBg: colors.green[700],
    focusedBorder: colors.green[700],
    disabledBg: colors.green[50],
    disabledText: colors.green[300],
    disabledBorder: colors.green[50],
  },
  tertiary: {
    bg: colors.base.white,
    text: colors.gray[800],
    border: colors.gray[300],
    hoverBg: colors.gray[50],
    focusedBorder: colors.primary[700],
    disabledBg: colors.gray[50],
    disabledText: colors.gray[300],
    disabledBorder: colors.gray[100],
  },
  outlineWhite: {
    bg: 'transparent',
    text: colors.gray[100],
    border: colors.gray[100],
    hoverBg: 'transparent',
    focusedBorder: 'transparent',
    disabledBg: 'transparent',
    disabledText: colors.gray[300],
    disabledBorder: colors.gray[100],
  },
  linkColor: {
    bg: 'transparent',
    text: colors.primary[500],
    border: 'transparent',
    hoverBg: 'transparent',
    focusedBorder: 'transparent',
    disabledBg: 'transparent',
    disabledText: colors.gray[300],
    disabledBorder: 'transparent',
  },
  linkGray: {
    bg: 'transparent',
    text: colors.gray[900],
    border: 'transparent',
    hoverBg: 'transparent',
    focusedBorder: 'transparent',
    disabledBg: 'transparent',
    disabledText: colors.gray[300],
    disabledBorder: 'transparent',
  },
  brand: {
    bg: 'transparent',
    text: 'transparent',
    border: 'transparent',
    hoverBg: 'transparent',
    focusedBorder: colors.gray[300],
    disabledBg: 'transparent',
    disabledText: 'transparent',
    disabledBorder: 'transparent',
  },
};

export const buttonSize = {
  sm: css`
    ${font('text', 'xs', '700')};
    font-size: 11.5px;
    padding: 6px 14px;
  `,
  md: css`
    ${font('text', 'sm', '700')};
    font-size: 14.4px;
    padding: 6px 14px;
  `,
  lg: css`
    ${font('text', 'lg', '700')};
    font-size: 18px;
    padding: 10px 22px;
  `,
  xl: css`
    ${font('text', 'xl', '700')};
    font-size: 22.5px;
    padding: 10px 22px;
  `,
};

export const getIcon = icon => {
  switch (icon) {
    case 'up':
      return <Icon id="FiArrowUp" size={20} />;
    case 'up-right':
      return <Icon id="FiArrowUpRight" size={20} />;
    case 'right':
      return <Icon id="FiArrowRight" size={20} />;
    case 'down-right':
      return <Icon id="FiArrowDownRight" size={20} />;
    case 'down':
      return <Icon id="FiArrowDown" size={20} />;
    case 'down-left':
      return <Icon id="FiArrowDownLeft" size={20} />;
    case 'left':
      return <Icon id="FiArrowLeft" size={20} />;
    case 'up-left':
      return <Icon id="FiArrowUpLeft" size={20} />;
    case 'play':
      return <Icon id="FiPlayCircle" size={20} />;
    case 'close':
      return <Icon id="FiX" size={20} />;
    case 'download':
      return <Icon id="FiDownload" size={20} />;
    default:
      return <Icon id="FiArrowRight" size={20} />;
  }
};

export const buttonAnimations = {
  scaleBounce: {
    base: css`
      transition-timing-function: cubic-bezier(0.47, 2.02, 1, 0.79);
      transition-duration: 0.15s;
    `,
    hover: css`
      transform: scale(1.1);
    `,
  },
  slideIcon: {
    base: css`
      transition-duration: 0.3s;
      svg {
        position: relative;
        left: 0;
        transition-duration: 0.3s;
      }
    `,
    hover: css`
      svg {
        left: 3px;
      }
    `,
  },
};
