import React from 'react';
import Button from '../styles/NavToggle.styled';

const NavToggle = ({ mobileNav, setMobileNav, setActiveDropdown }) => (
  <Button
    className={mobileNav ? 'active' : ''}
    onClick={() => {
      setMobileNav(!mobileNav);
      setActiveDropdown(null);
    }}
  >
    <svg
      width="27"
      height="19"
      viewBox="0 0 27 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="26.5"
        height="2.5"
        rx="1.25"
        transform="matrix(1 0 0 -1 0 18.5)"
        fill="#1D2030"
      />
      <rect
        width="26.5"
        height="2.5"
        rx="1.25"
        transform="matrix(1 0 0 -1 0 10.5)"
        fill="#1D2030"
      />
      <rect
        width="26.5"
        height="2.5"
        rx="1.25"
        transform="matrix(1 0 0 -1 0 2.5)"
        fill="#1D2030"
      />
    </svg>
  </Button>
);

export default NavToggle;
