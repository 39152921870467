import styled from 'styled-components';
import { font } from '../../atoms/typography';

export default styled.div`
  display: inline-block;

  &.hide-modal {
    position: absolute;
    bottom: 0;
  }

  .modal {
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    overflow-x: hidden;
    background-color: rgba(0, 0, 0, 0.9);
    pointer-events: none;
    opacity: 0;
    transition: opacity 250ms 700ms ease;

    &.visible {
      pointer-events: auto;
      opacity: 1;
      transition: all 300ms ease-in-out;
      height: 100vh;

      .modal_wrap {
        opacity: 1;
        transform: scale(1);
        transition: opacity 250ms 500ms ease, transform 350ms 500ms ease;
      }
    }

    .popup {
      .close_btn {
        height: fit-content;
        margin-right: -16px;
      }
    }

    .modal-wrapper {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      .roi-content {
        background-color: var(--white);
        border-radius: 16px;
        overflow: hidden;
        height: 470px;
        width: 570px;

        @media (max-width: 991px) {
          max-width: 370px;
          height: auto;
        }

        @media (max-width: 768px) {
          max-width: 298px;
        }

        .cta-wrapper {
          text-align: center;
          padding: 0 32px 32px;

          @media (max-width: 991px) {
            padding: 0 24px 24px;
          }

          .kicker {
            ${font('overline', 'sm', '700')}
            color: #1c3fff;
            margin-top: 16px;
          }

          .heading {
            margin-bottom: 12px;
          }

          .sub-head {
            font-size: 16.2px;
            color: #2c3641;
            margin-bottom: 32px;
          }

          .cta-roi {
            padding: 12px 18px;
            background: #1c3fff !important;
            box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
            border-radius: 3px;
            border: none;
            text-decoration: none;
            font-weight: 900;
            font-size: 12px;
            line-height: 13px;
            letter-spacing: 1.4px;
            text-transform: uppercase;
            color: #f7f9fc;

            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }
  .modal_wrap {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    background-color: transparent;
    align-self: center;
    opacity: 0;
    transform: scale(0.6);
    transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
    transform: scale(0);
    @media (max-width: 991.98px) {
      width: 100%;
      min-width: auto;
      padding: 10px;
    }
    .close_btn {
      cursor: pointer;
      width: 16px;
      height: 16px;
      position: absolute;
      right: 30px;
      top: 30px;
      @media (max-width: 991.98px) {
        right: 15px;
        top: -10px;
      }
    }
    .video-container {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .videoframe {
        margin-bottom: 12px;
        width: 80%;
        height: 80%;
      }
    }

    .modal_heading {
      ${font('display', 'sm', '700')}
      text-align: left;
      color: #ffffff;
    }
    .modal_subheading {
      ${font('text', 'md', '400')}
      text-align: left;
      color: rgba(255, 255, 255, 0.8);
    }
    .companylogo {
      text-align: left;
      margin-bottom: 10px;
      img {
        max-height: 27px;
      }
    }
    button {
      background-position: center;
      transition: all 150ms ease;
      transform: perspective(1px) translateZ(0);
      transition-timing-function: cubic-bezier(0.47, 2.02, 1, 0.79);
      transition-duration: 0.15s;
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .modal.demo {
    @media (max-width: 768px) {
      overflow: auto;
    }
  }

  .demo-form {
    display: flex;
    justify-content: center;
    align-items: center;

    .close_btn {
      right: 90px;
      @media (max-width: 991px) {
        right: 15px;
      }
    }

    @media (max-width: 768px) {
      min-width: auto;
      width: 100%;
    }
  }
`;
