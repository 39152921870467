import React from 'react';

import Link from '../../../utils/Link';
import OptimizedImage from '../../../utils/OptimizedImage';

import {
  Container,
  Icon,
  Text,
  List,
  ListItem,
} from '../styles/FooterContact.styled';

const FooterContact = ({ icon, heading, subhead, contactOptions }) => (
  <Container>
    {icon && (
      <Icon>
        <OptimizedImage src={icon?.image?.url} alt="Shopmonkey Footer Icon" />
      </Icon>
    )}
    <Text>
      {heading && <p className="heading">{heading}</p>}
      {subhead && <p className="subhead">{subhead}</p>}
    </Text>
    <List>
      {contactOptions.map(option => (
        <ListItem key={option.id}>
          <Link to={option?.url} className="contact-link" openInNewTab>
            <div className="icon">
              <OptimizedImage
                image={option?.icon?.image?.gatsbyImageData}
                src={option?.icon?.image?.url}
                alt={option?.icon?.internalName}
                title={option?.icon?.image?.title}
                width="22px"
                height="22px"
              />
            </div>
            <div className="text">
              <p className="heading">{option?.heading}</p>
              <div
                className="subhead"
                dangerouslySetInnerHTML={{ __html: option?.subhead }}
              />
            </div>
          </Link>
        </ListItem>
      ))}
    </List>
  </Container>
);

export default FooterContact;
