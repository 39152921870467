import { css } from 'styled-components';

const autoCheck = prop => {
  return prop === 'auto' ? prop : `${prop}px`;
};

export const paddingX = (left, right = null) => css`
  padding-left: ${left}px;
  padding-right: ${right || left}px;
`;

export const paddingY = (top, bottom = null) => css`
  padding-top: ${top}px;
  padding-bottom: ${bottom || top}px;
`;

export const padding = (left, top, right = null, bottom = null) => css`
  ${paddingX(left, right || left)}
  ${paddingY(top || left, bottom || top || left)}
`;

export const marginX = (left, right = null) => css`
  margin-left: ${autoCheck(left)};
  margin-right: ${autoCheck(right || left)};
`;

export const marginY = (top, bottom = null) => css`
  margin-top: ${autoCheck(top)};
  margin-bottom: ${autoCheck(bottom || top)};
`;

export const margin = (left, top, right = null, bottom = null) => css`
  ${marginX(left, right || left)}
  ${marginY(top || left, bottom || top || left)}
`;
