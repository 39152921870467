import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../styles/atoms/colors';
import { font } from '../../styles/atoms/typography';

const badgeSizes = {
  sm: css`
    padding: 2px 6px;
  `,
  md: css`
    padding: 2px 10px;
  `,
  lg: css`
    padding: 4px 12px;
  `,
};

const Badge = ({
  badgeColor,
  children,
  className = '',
  onClick,
  preserveCasing,
  size,
  textColor
}) => (
  <Container
    className={`${className}${preserveCasing ? '' : ' capitalize'}`}
    size={size}
    badgeColor={badgeColor}
    textColor={textColor}
    onClick={onClick}
  >
    {children}
  </Container>
);

export default Badge;

export const Container = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  ${props => badgeSizes[props.size || 'sm']}
  ${props => css`
    background: ${props.badgeColor || colors.primary[500]};
  `}
  ${props => css`
    color: ${props.textColor || colors.base.white};
  `}
  ${font('text', 'xs', '500')}
  ${props => props.onClick && 'cursor: pointer'}
  white-space: nowrap;
  transition: 200ms;

  &.capitalize {
    text-transform: capitalize;
  }
`;
