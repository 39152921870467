import React from 'react';
import MobileDropdown from './MobileDropdown';
import Link from '../../../utils/Link';
import ListItem from '../styles/MobileNavItem.styled';
import Icon from '../../../styles/atoms/icons';

const MobileNavItem = ({ index, data, activeDropdown, setActiveDropdown }) => {
  const handleDropdown = (activeDropdown, index) => {
    if (activeDropdown === index) {
      setActiveDropdown(null);
      return;
    }
    setActiveDropdown(index);
  };

  return (
    <ListItem>
      {data?.childMenuItem?.length !== 0 ? (
        <>
          <button
            type="button"
            onClick={() => {
              handleDropdown(activeDropdown, index);
            }}
            className={activeDropdown === index ? 'active' : ''}
          >
            {data?.label}
            <Icon id="carrot" className="carrot-icon" isImage />
          </button>
          <MobileDropdown
            index={index}
            data={data}
            activeDropdown={activeDropdown}
          />
        </>
      ) : (
        <Link className="nav-link" to={data?.menuItemCallToAction?.url}>
          {data?.label}
        </Link>
      )}
    </ListItem>
  );
};

export default MobileNavItem;
