import { colors } from '../../../styles/atoms/colors';
import { toCamelCase } from '../../../utils/helperFunctions';

const colorMap = {
  charcoal: colors.gray[900],
  light: colors.base.white,
  gray: colors.base.heroSurface,
  blue: colors.primary[900],
  lightBlue: colors.primary[50],
  green: colors.green[800],
};

const getTheme = bgColor => {
  const isNotHex = !bgColor.includes('#');
  const color = isNotHex ? colorMap[toCamelCase(bgColor)] : bgColor;
  const darkThemes = [colors.gray[900], colors.primary[900], colors.green[800]];
  const isDark = darkThemes.includes(color);
  const theme = isDark ? 'dark' : 'light';

  return { background: color, theme };
};

export default getTheme;
