import React, { useContext, useEffect, useState } from 'react';
import AppStoreBadge from '../../images/app-store-badge.svg';
import PlayStoreBadge from '../../images/play-store-badge.png';
import Link from '../../utils/Link';
import OptimizedImage from '../../utils/OptimizedImage';
import { isExternalLink } from '../../utils/links/isExternalLink';
import { ModalContext } from '../Modal/context/ModalContainerContext';
import { Cta } from './CallToAction.styles';
import { getIcon } from './buttonHierarchy';

const CallToAction = ({
  variant,
  value,
  link,
  popupReference,
  size,
  icon,
  scrollId,
  disabled,
  reverse,
  customHandleClick,
  className,
  openInNewTab = false,
  containerClassName = '',
  ...rest
}) => {
  const [linkClickable, setLinkClickable] = useState(true);

  const { modalDispatch } = useContext(ModalContext);

  useEffect(() => {
    if (scrollId || popupReference || customHandleClick) {
      setLinkClickable(false);
    }
  }, [scrollId, popupReference, customHandleClick]);

  const handleClick = event => {
    if (disabled) {
      return;
    }

    if (scrollId || popupReference || customHandleClick) {
      event.preventDefault();
    }

    if (popupReference) {
      modalDispatch({ type: 'OPEN_MODAL', payload: popupReference });
    }

    if (scrollId) {
      setTimeout(() => {
        typeof window !== 'undefined' &&
          document.getElementById(scrollId.replace('#', ''))?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
      }, 0);
    }

    customHandleClick && customHandleClick();
  };

  const externalLink = link ? isExternalLink(link, openInNewTab) : false;
  const isAppStore = link && link.includes('apps.apple.com');
  return (
    <Cta
      size={size || 'lg'}
      variant={variant}
      disabled={disabled}
      reverse={reverse}
      linkClickable={linkClickable}
      onClick={event => handleClick(event)}
      className={containerClassName}
      {...rest}
    >
      <Link
        to={link}
        className={`cta${className ? ` ${className}` : ''}`}
        role={!link ? 'button' : 'link'}
        openInNewTab={externalLink}
      >
        <>
          {value}
          {icon && getIcon(icon)}
          {variant === 'brand' && (
            <OptimizedImage
              className="app-badge"
              src={isAppStore ? AppStoreBadge : PlayStoreBadge}
              alt={isAppStore ? 'App Store Badge' : 'Play Store Badge'}
              width="176"
              loading="lazy"
            />
          )}
        </>
      </Link>
    </Cta>
  );
};

export default CallToAction;
