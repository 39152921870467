export const setMenuData = menuItem => [
  {
    id: 0,
    items: [menuItem[0], menuItem[1]],
  },
  {
    id: 1,
    items: [menuItem[2], menuItem[3]],
  },
];
