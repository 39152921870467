export const colors = {
  base: {
    white: '#FFFFFF',
    black: '#000000',
    heroSurface: '#F9FBFE',
    light: '#FAFCFE',
  },
  gray: {
    50: '#F5F6FA',
    100: '#F0F1F7',
    200: '#D8DAE6',
    300: '#C0C3D1',
    400: '#A5A8B8',
    500: '#86899A',
    600: '#6F7283',
    700: '#4D5061',
    800: '#343643',
    900: '#1D2030',
  },
  primary: {
    50: '#E9F2FF',
    100: '#C8D0FF',
    200: '#A0AEFF',
    300: '#7489FF',
    400: '#4E60FF',
    500: '#1C3EFF',
    600: '#1834F3',
    700: '#0027E6',
    800: '#0015DB',
    900: '#001FCC',
  },
  green: {
    50: '#E8FBF9',
    100: '#C7F6ED',
    200: '#A0EDDE',
    300: '#60DDC4',
    400: '#01C9A1',
    500: '#00B591',
    600: '#00A081',
    700: '#007D64',
    800: '#005E4B',
    900: '#014739',
  },
  aqua: {
    50: '#E5FAFD',
    100: '#C0F3FA',
    200: '#95EAF5',
    300: '#68DBEB',
    400: '#26CCE2',
    500: '#0DB4CB',
    600: '#0CA2B6',
    700: '#00889A',
    800: '#007585',
    900: '#00525E',
  },
  purple: {
    50: '#F5E5F6',
    100: '#F1DEFF',
    200: '#E3BDFF',
    300: '#D7A0FF',
    400: '#C472FF',
    500: '#B555FA',
    600: '#A040E5',
    700: '#8329C5',
    800: '#6E14AF',
    900: '#590894',
  },
  yellow: {
    50: '#FFF8D4',
    100: '#FFF4B9',
    200: '#FFEC87',
    300: '#FFE459',
    400: '#FFDA18',
    500: '#FAD016',
    600: '#F0C209',
    700: '#DEAD0B',
    800: '#C49300',
    900: '#A57901',
  },
  orange: {
    50: '#FBEDEB',
    100: '#FFD7C6',
    200: '#FFBB9D',
    300: '#FF996D',
    400: '#FF804A',
    500: '#FC6A2C',
    600: '#F05E1F',
    700: '#D74E13',
    800: '#B93E09',
    900: '#952D00',
  },
  red: {
    50: '#F7E4E4',
    100: '#F1D2D2',
    200: '#ECA7A7',
    300: '#E77575',
    400: '#EC6060',
    500: '#E34646',
    600: '#D93030',
    700: '#B71A1A',
    800: '#960606',
    900: '#780000',
  },
  brown: {
    50: '#F5E8E2',
    100: '#DBC7C0',
    200: '#CFAB9E',
    300: '#B98F7E',
    400: '#A97966',
    500: '#9F634C',
    600: '#924E34',
    700: '#6F2D14',
    800: '#511903',
    900: '#381000',
  },
  error: {
    25: '#FFFBFA',
    50: '#FEF3F2',
    100: '#FEE4E2',
    200: '#FECDCA',
    300: '#FDA29B',
    400: '#F97066',
    500: '#F04438',
    600: '#D92D20',
    700: '#B42318',
    800: '#912018',
    900: '#7A271A',
  },
  warning: {
    25: '#FFFCF5',
    50: '#FFFAEB',
    100: '#FEF0C7',
    200: '#FEDF89',
    300: '#FEC84B',
    400: '#FDB022',
    500: '#F79009',
    600: '#DC6803',
    700: '#B54708',
    800: '#93370D',
    900: '#7A2E0E',
  },
};

export const convertToRGB = hex => {
  hex = hex.replace('#', '');
  if (hex.length !== 6) hex = '133OCD';

  const splitHex = hex.match(/.{1,2}/g);
  const rgb = {
    r: parseInt(splitHex[0], 16),
    g: parseInt(splitHex[1], 16),
    b: parseInt(splitHex[2], 16),
  };

  return rgb;
};

export const colorTransparency = (color, alpha = 0.9) => {
  const { r, g, b } = convertToRGB(color);
  const colorString = `rgba(${r}, ${g}, ${b}, ${alpha})`;

  return colorString;
};
