import React, { createContext } from 'react';

export const SectionThemeContext = createContext(null);

const SectionThemeProvider = ({ children, initialState }) => (
  <SectionThemeContext.Provider value={initialState}>
    {children}
  </SectionThemeContext.Provider>
);

export default SectionThemeProvider;
