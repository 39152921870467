import styled from 'styled-components';

import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';
import { atMinWidth } from '../../../styles/atoms/breakpoints';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  .heading {
    ${font('text', 'md', '700')}
    color: ${colors.base.white};
  }
  .icons {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 18px;
    ${atMinWidth.sm`
      gap: 24px;
    `}
    .social-link svg {
      width: 24px;
      height: 24px;
      color: ${colors.base.white};
      transition: color 0.3s ease-in-out;
      &:hover {
        color: ${colors.gray[500]};
      }
    }
  }
`;
