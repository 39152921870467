import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

const checkLinkType = to => {
  return /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(
    to
  );
};

const removeDuplicate = (to, url) => {
  const entries = Object.fromEntries(new URLSearchParams(url));
  const keys = Object.keys(entries);
  keys.forEach(key => {
    if (disallowList.includes(key)) {
      delete entries[key];
    }
  });
  const newUrl = new URLSearchParams(entries).toString();
  return `${to}?${newUrl}`;
};

const PersistLink = ({ to, className, target, children, ...props }) => {
  if (typeof window !== 'undefined') {
    const link = location.search.includes('?')
      ? removeDuplicate(to, location.search)
      : to;
    if (checkLinkType(to)) {
      return (
        <a
          className={className}
          href={link}
          target={target ? target : '_blank'}
          rel="noopener noreferrer"
          {...props}
        >
          {children}
        </a>
      );
    }
    return (
      <GatsbyLink className={className} to={link} {...props}>
        {children}
      </GatsbyLink>
    );
  }
  return null;
};

const disallowList = [
  'topic',
  'category',
  'p',
  'shopType',
  'geolocation',
  'switchedFrom',
  'type',
  'collection',
];

export default PersistLink;
