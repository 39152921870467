import { css } from 'styled-components';
import { convertToRGB } from './colors';

export const shadowSizes = {
  xs: [
    {
      x: '0',
      y: '1',
      blur: '2',
      spread: '0',
      opacity: '0.05',
    },
  ],
  sm: [
    {
      x: '0',
      y: '1',
      blur: '2',
      spread: '0',
      opacity: '0.06',
    },
    {
      x: '0',
      y: '1',
      blur: '3',
      spread: '0',
      opacity: '0.1',
    },
  ],
  md: [
    {
      x: '0',
      y: '2',
      blur: '4',
      spread: '-2',
      opacity: '0.07',
    },
    {
      x: '0',
      y: '4',
      blur: '8',
      spread: '-2',
      opacity: '.1',
    },
  ],
  lg: [
    {
      x: '0',
      y: '4',
      blur: '6',
      spread: '-2',
      opacity: '0.05',
    },
    {
      x: '0',
      y: '12',
      blur: '16',
      spread: '-4',
      opacity: '0.1',
    },
  ],
  xl: [
    {
      x: '0',
      y: '8',
      blur: '8',
      spread: '-4',
      opacity: '0.04',
    },
    {
      x: '0',
      y: '8',
      blur: '24',
      spread: '-4',
      opacity: '0.1',
    },
  ],
  xxl: [
    {
      x: '0',
      y: '24',
      blur: '48',
      spread: '-12',
      opacity: '0.25',
    },
  ],
  xxxl: [
    {
      x: '0',
      y: '32',
      blur: '64',
      spread: '-12',
      opacity: '0.2',
    },
  ],
  none: [
    {
      x: '0',
      y: '0',
      blur: '0',
      spread: '0',
      opacity: '0',
    },
  ],
  focusRing: [
    {
      x: '0',
      y: '0',
      blur: '0',
      spread: '4',
      opacity: '0.2',
    },
  ],
  conversionPanel: [
    {
      x: '0',
      y: '8',
      blur: '60',
      spread: '0',
      opacity: '0.2',
    },
  ],
};

/*
 * Usage: ${shadow('xs')} or ${shadow('xxl', colors.purple[500])}
 */
const shadow = (size, color = '#133OCD') => {
  const { r, g, b } = convertToRGB(color);

  const units = shadowSizes[size].map(
    ({ x, y, blur, spread, opacity }, index) => {
      const unitString = `${x}px ${y}px ${blur}px ${spread}px`;
      const colorString = `rgba(${r}, ${g}, ${b}, ${opacity})`;
      const deliminator = index + 1 === shadowSizes[size].length ? ';' : ',';

      return `${unitString} ${colorString} ${deliminator}`;
    }
  );

  return css`
    box-shadow: ${units};
  `;
};

export default shadow;
