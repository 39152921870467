import { graphql, useStaticQuery } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import React from 'react';
import { renderMetaTags } from 'react-datocms';

function SEO({ headHtml, indexable, schemaMarkup, seo, slug }) {
  const {
    site: { favicon },
  } = useStaticQuery(query);
  const seoTags = Array.isArray(seo?.tags)
    ? seo.tags.map(tag => {
        const updatedTag = { ...tag };

        // Add tag key for support with renderMetaTags
        updatedTag.tag = updatedTag.tagName;

        return updatedTag;
      })
    : [];
  const titleTag = seoTags.find(tag => tag.tag === 'title');

  if (titleTag) {
    if (titleTag.content.endsWith(' | Shopmonkey | Shopmonkey')) {
      // Prevent duplicate suffixes
      titleTag.content = titleTag.content.replace(
        ' | Shopmonkey | Shopmonkey',
        ' | Shopmonkey'
      );
    } else if (!titleTag.content.endsWith(' | Shopmonkey')) {
      // Make sure suffix is added even if Dato removes it (when title >= 60 characters)
      titleTag.content = `${titleTag.content} | Shopmonkey`;
    }
  }

  return (
    <>
      {/* Use renderMetaTags instead of passing seo into HelmetDatoCms to fix a
      build issue around static HTML file content not matching page-data.json */}
      {seoTags.length > 0 && renderMetaTags(seoTags)}
      <HelmetDatoCms favicon={favicon} />
      <meta
        id="robots"
        name="robots"
        content={indexable ? 'index' : 'noindex'}
      />
      {headHtml && headHtml}
      {schemaMarkup?.length > 0 &&
        schemaMarkup.map(({ id, schema }) => (
          <script key={id} id={id} type="application/ld+json">
            {schema}
          </script>
        ))}
      {slug === 'home-v2' && (
        <link
          rel="canonical"
          id="canonical"
          href="https://www.shopmonkey.io/"
          data-baseprotocol="https:"
          data-basehost="www.shopmonkey.io"
        />
      )}
    </>
  );
}

export const query = graphql`
  {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
  }
`;

export default SEO;
