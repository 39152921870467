/**
 * @param {string} string string, including ones already in camelCase
 * @returns the string in camelCase form
 */
export const toCamelCase = string =>
  string
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (_m, chr) => chr.toUpperCase());

/**
 * @param {string} text any string, including ones already in camelCase
 * @param {string} separator any string
 * @param {boolean} preserveConsecutiveUppercase - Dictate if consecutive caps are preserved
 *
 * decamelize(thisIsCamelCase) -> This Is Camel Case
 * decamelize(iWantIceCream, {separator: '-'}) -> I-Want-Ice-Cream
 * decamelize(backInTheUSSR, {preserveConsecutiveUppercase: true}) -> Back In The USSR
 *
 * @returns a string with words separated by desired separator
 */
export const decamelize = (
  text,
  { separator = ' ', preserveConsecutiveUppercase = true } = {}
) => {
  const splitText = text.split('');

  const decamelized = splitText
    .map((char, ind) => {
      if (char.match(/[A-Z]/)) {
        const acronym =
          preserveConsecutiveUppercase &&
          splitText[ind + 1]?.match(/[A-Z]/) &&
          splitText[ind - 1]?.match(/[A-Z]/);

        separator = acronym ? '' : separator;

        if (
          splitText[ind - 1]?.match(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/)
        ) {
          return char;
        }

        return `${separator}${char}`;
      }

      char = ind === 0 ? char.toUpperCase() : char;

      return char;
    })
    .join('');

  return decamelized;
};
