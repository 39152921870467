import React, { forwardRef } from 'react';
import { colors } from '../../styles/atoms/colors';
import SectionThemeProvider from './context/SectionContext';
import { InnerWrapper, SectionStyle } from './Section.styles';
import SectionBg from './SectionBg/SectionBg';
import Hexes from './SectionBg/SectionBgHexes';
import WaveBg from './SectionBg/WaveBg';
import getTheme from './utils/getTheme';

const Section = forwardRef(
  (
    {
      backgroundImageName,
      bgColor,
      hasSvgHexes,
      children,
      id,
      style,
      innerStyle,
      className,
      innerClassName,
      hexVariant,
      hasWave,
      showYOverflow,
    },
    ref
  ) => {
    const recordId = id?.match(/DatoCms(?:.*?)-(.*)-[a-z]{1,3}$/)?.[1] || id;
    const { theme, background } = getTheme(bgColor || colors.base.light);

    return (
      <SectionThemeProvider initialState={{ theme }}>
        <SectionStyle
          ref={ref}
          className={className}
          bgColor={background}
          id={recordId}
          customStyle={style}
          hasSvgHexes={hasSvgHexes}
          theme={theme}
        >
          {hasSvgHexes && (
            <Hexes
              theme={theme}
              variant={hexVariant}
              showOverflow={showYOverflow}
            />
          )}
          {hasWave && <WaveBg />}
          <InnerWrapper className={innerClassName} customStyle={innerStyle}>
            {backgroundImageName && (
              <SectionBg backgroundName={backgroundImageName} />
            )}
            {children}
          </InnerWrapper>
        </SectionStyle>
      </SectionThemeProvider>
    );
  }
);

export default Section;
