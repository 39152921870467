import styled, { css } from 'styled-components';
import { colors } from '../../atoms/colors';

export const AnnouncementWrapper = styled.div`
  position: ${props => (props.changeNav ? 'fixed' : 'relative')};
  background: ${colors.base.white};
  z-index: 10001;
  overflow: hidden;
  ${props =>
    props.changeNav
      ? css`
          top: 0px;
          display: none;
        `
      : null}

  @media (min-width: 1199.98px) {
    display: flex;
    font-size: 12px;
    color: #2c3641;
    top: 0;
    width: 100%;
    max-width: 1702px;
    height: 40px;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Heading = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: row;
  max-width: 1702px;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 1200px) {
    margin-left: 0 !important;
  }

  @media (max-width: 768px) {
    padding-bottom: 8px;
    border-bottom: 1px solid #f8fbfe;
  }

  .text {
    justify-self: flex-start;
    align-self: center;
    /* Match Mutiny banner timing */
    transition: opacity 0.8s cubic-bezier(0.3, 0, 0.175, 1) 0s;

    /* Hide if a Mutiny banner is open */
    body:has([id*='mutiny-preact'] [class*='open'] > .mutiny-banner)
      #___gatsby
      & {
      opacity: 0;
      pointer-events: none;
    }

    a {
      font-weight: 600;
    }

    @media (max-width: 1200px) {
      font-size: 14px;
    }

    @media (max-width: 768px) {
      font-size: 12px;
      width: 100%;
      padding-left: 8px;
    }
  }

  .action-links {
    text-transform: uppercase;
    justify-self: flex-end;

    @media (max-width: 1200px) {
      font-size: 12px;
    }

    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      width: 100%;
      display: none;
    }

    .call-label {
      margin-left: 4px;
      letter-spacing: 1.4px;
      font-weight: 600;
    }

    .call-number {
      font-weight: 600;
    }

    .sign-in {
      color: #2c3641;
      letter-spacing: 1.4px;
      vertical-align: 8px;
      font-weight: 600;
    }

    .phone-wrapper {
      vertical-align: 8px;
      color: #2c3641;
      .phone-icon {
        color: #2c3641;
      }
    }

    .separator {
      display: inline-block;
      width: 1px;
      height: 24px;
      margin-top: 8px;
      margin-left: 12px;
      margin-right: 12px;
      background: #c2c6cc;
    }
  }

  .read-more {
    color: #0015ca !important;
    text-decoration: underline;
  }
`;
