import styled from 'styled-components';
import { m } from 'framer-motion';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { font } from '../../../styles/atoms/typography';
import { colors } from '../../../styles/atoms/colors';

export const Dropdown = styled(m.div)`
  width: 100%;
  pointer-events: none;
  padding-bottom: 16px;
  border-bottom: 1px solid ${colors.gray[100]};
  &.active {
    pointer-events: all;
  }
`;
export const Menu = styled.div`
  width: 100%;
  height: auto;
  padding: 24px;
  ${props => (props?.background ? `background: ${props?.background};` : '')}
  .menu-heading {
    width: max-content;
    height: auto;
    ${font('overline', 'md', '700')}
    display: flex;
    align-items: center;
    color: ${colors.gray[700]};
    padding-bottom: 8px;
    border-bottom: 0.5px solid ${colors.gray[200]};
    margin-bottom: 24px;
  }
`;
export const List = styled.ul`
  width: 100%;
  height: auto;
  list-style: none;
  li:last-child {
    margin-bottom: 0px;
  }
`;
export const ListItem = styled.li`
  width: 100%;
  height: auto;
  margin-bottom: 32px;
  a {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    text-decoration: none;
    text-transform: none;
    padding: 0px;
    border: none;
    &:hover,
    &:focus {
      text-decoration: none;
      .arrow-icon {
        opacity: 1;
        transform: rotate(180deg) translateX(-12px);
      }
    }
  }
  .icon {
    width: 32px;
    height: 32px;
    object-fit: contain;
    margin-right: 16px;
  }
  .full-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 16px;
  }
`;
export const Text = styled.div`
  .heading {
    ${font('text', 'md', '500')}
    text-transform: none;
    color: ${colors.gray[900]};
    margin: 0px;
    .arrow-icon {
      transform: rotate(180deg) translateX(20px);
      opacity: 0;
      transition: all 200ms ease-in-out;
      width: 16px;
      height: 16px;
    }
  }
  .subhead {
    display: none;
    ${props => props?.showSubhead && `display: block;`}
    ${font('text', 'sm', '500')}
    color: ${colors.gray[600]};
    margin: 0px;
    ${atMinWidth.sm`
      display: flex;
      flex-direction: column;
    `}
    p,
    span {
      display: flex;
      align-items: center;
      ${font('text', 'sm', '500')}
      color: ${colors.gray[600]};
      margin: 0px;
    }
    p {
      margin-bottom: 8px;
    }
  }
`;
