export const isExternalLink = (link, openInNewTab = false) => {
  if (openInNewTab) {
    return true;
  }

  if (link.includes('shopmonkey.io') || link.charAt(0) === '/' || link.charAt(0) === '#') {
    return false;
  }

  return true;
};
