import React from 'react';
import { Wave, WaveWrapper } from './SectionBgHexes.styles';

const WaveBg = ({
  className,
  fill = '#E9F2FF',
  height = 1067,
  stroke = 'none',
  strokeWidth = 0,
  width = 1844,
  opacity = 1,
}) => (
  <WaveWrapper className={className}>
    <Wave
      width={width}
      height={height}
      viewBox="0 0 1844 1067"
      fill={fill}
      stroke={stroke}
      opacity={opacity}
      strokeWidth={strokeWidth}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1843.27 267.218V1066.63L0 1065.44V894.402C0 840.951 25.4595 791.065 66.1946 766.121L1288.25 5.93892C1294.36 2.37557 1302.51 0 1309.64 0C1334.08 0 1354.44 23.7557 1354.44 53.4503V420.442C1354.44 428.757 1355.46 437.071 1359.54 444.198C1370.74 466.766 1396.2 475.08 1415.55 462.014L1775.04 220.894C1782.16 216.143 1789.29 213.767 1797.44 213.767C1822.9 213.767 1843.27 237.523 1843.27 267.218Z" />
    </Wave>
  </WaveWrapper>
);

export default WaveBg;
