import React from 'react';
import styled from 'styled-components';

const Icon = ({
  id,
  size,
  color,
  fill,
  stroke,
  className,
  isImage,
  viewBox,
  style,
}) => (
  <Svg
    className={className}
    color={color}
    width={!isImage ? size || '1em' : undefined}
    height={!isImage ? size || '1em' : undefined}
    viewBox={viewBox}
    style={style}
  >
    <use
      fill={fill}
      stroke={stroke}
      href={
        isImage ? `/images/imageSprite.svg#${id}` : `/icons/sprites.svg#${id}`
      }
    />
  </Svg>
);

export default Icon;

export const Svg = styled.svg`
  color: ${props => props.color};
  min-width: ${props => props.width}px;
  min-height: ${props => props.height}px;
`;
