import styled, { css } from 'styled-components';
import Section from '../../molecules/Section/Section';
import { atMinWidth } from '../../styles/atoms/breakpoints';
import { colors } from '../../styles/atoms/colors';
import shadow from '../../styles/atoms/shadows';
import { font } from '../../styles/atoms/typography';

export const CustomSectionStyles = styled(Section)`
  margin-top: ${props => props.customMarginTop};
  background: ${props => props.backgroundGradient};
`;

export const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: ${props => (props.align === 'left' ? 'flex-start' : 'center')};
  gap: 24px;
  text-align: ${props => props.align};
`;

export const Wrapper = styled.div`
  position: relative;
  display: ${props => (props.size === 'Container' ? 'flex' : 'contents')};
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  min-height: 314px;
  padding: 48px 24px;
  background-color: ${props =>
    props.variant !== 'Form-V2' && colors.base.white};
  border-radius: ${props => (props.size === 'Container' ? '16px' : '')};
  ${props => props.variant !== 'Form-V2' && shadow('conversionPanel')};

  .gatsby-image-wrapper,
  .container-background-image {
    position: absolute;
    object-fit: cover;
    object-position: left;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    border-radius: 16px;

    img {
      object-position: left;
    }

    ${atMinWidth.lg`
      object-position: center;

      img {
        object-position: center;
      }
    `}
  }

  ${atMinWidth.sm`
    padding: 48px 72px;
    min-height: auto;

    &:before, &:after {
      display: none;
    }
  `}

  & > div {
    z-index: 3;
  }

  & ${Content} {
    max-width: ${props => (props.size === 'Container' ? '640px' : '')};
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  gap: ${props => (props.size === 'Container' ? '60px' : '30px')};
  ${props =>
    props.center &&
    css`
      justify-content: center;
      position: relative;
    `}

  .form-only {
    max-width: 55%;
    box-shadow: 0px 24px 48px -12px rgba(19, 48, 205, 0.25);
    padding: 48px;
  }

  ${atMinWidth.lg`
    flex-direction: row;
  `}

  & > div {
    flex: 1 0 45%;
  }
`;

export const Kicker = styled.p`
  ${font('overline', 'sm', '700')}
  color: ${props =>
    props.theme === 'Dark' ? colors.base.white : colors.primary[500]};
`;

export const Heading = styled.p`
  ${props =>
    props.variant === 'Form'
      ? font('display', 'md', '700')
      : props.size === 'Container'
      ? font('display', 'sm', '700')
      : font('display', 'md', '700')};
  color: ${props =>
    props.theme === 'Dark' ? colors.base.white : colors.gray[900]};

  ${atMinWidth.sm`
    ${props =>
      props.size === 'Container'
        ? font('display', 'md', '700')
        : font('display', 'lg', '700')};
  `}

  ${atMinWidth.md`
    ${font('display', 'lg', '700')};
  `}
`;

export const Subhead = styled.div`
  ${font('text', 'md', '400')}
  color: ${props =>
    props.theme === 'Dark' ? colors.gray[100] : colors.gray[600]};
  ${atMinWidth.sm`
    ${font('text', 'lg', '400')}
  `}
  color: ${props => props.variant === 'V2' && colors.gray[400]}
`;

export const ReferenceWrapper = styled.div`
  background-color: ${colors.base.white};
  border-radius: 12px;
  padding: 32px;
  ${shadow('xl')}
`;

export const TestimonialWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  gap: 24px;
  margin-top: 32px;

  img,
  .letterAvatar {
    border-radius: 50%;
    width: 64px;
    height: 64px;
  }

  .letterAvatar {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .customer-info {
    display: flex;
    flex-flow: column nowrap;
    gap: 6px;

    .name {
      ${font('text', 'sm', '700')}
      color: ${colors.gray[900]};

      ${atMinWidth.sm`
        ${font('text', 'md', '700')}
      `}
    }

    .role {
      ${font('text', 'sm', '400')}
      color: ${colors.gray[600]}

      ${atMinWidth.sm`
        ${font('text', 'md', '400')}
      `}
    }
  }
`;

export const BlogConversionPanelStyles = styled.div`
  padding: 40px;
  background-color: ${colors.base.white};
  box-shadow: 0px 4px 8px -2px rgba(19, 48, 205, 0.1),
    0px 2px 4px -2px rgba(19, 48, 205, 0.06);

  @media (max-width: 767.98px) {
    margin-bottom: 50px;
  }

  .heading-block {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    svg {
      width: 48px;
      height: 48px;
      margin-bottom: 0;
      margin-right: 16px;
    }
  }

  .subhead {
    color: ${colors.gray[600]};
    margin-bottom: 24px;
  }
`;

export const BlogConversionPanelHeading = styled.p`
  ${font('text', 'sm', '700')}
  margin-bottom: 0;
`;
