import styled from 'styled-components';

import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';
import { atMinWidth } from '../../../styles/atoms/breakpoints';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  max-width: 840px;
  padding: 0px 23.5px;
  margin: 0px auto;
  ${atMinWidth.sm`
    max-width: 1240px;
    padding: 0px 35px;
  `}
`;
export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  ${atMinWidth.xl`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}
`;
export const Text = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    text-align: left;
    ${atMinWidth.sm`
        flex-direction: row;
    `}
    .icon {
        width: 32px;
        height: 32px;
        img {
          width: 32px;
          height: 32px;
            object-fit: contain;
        }
    }
    .heading {
        ${font('text', 'sm', '400')}
        color: ${colors.gray[400]};
    }
`;
export const Links = styled.div`
  display: flex;
  gap: 48px;
  .privacy-link {
    ${font('text', 'sm', '400')}
    color: ${colors.base.white};
    transition: color 0.3s ease-in-out;
    &:hover {
        color: ${colors.gray[500]};
        text-decoration: none;
    }
  }
`;
export const Disclaimers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-top: 1px solid ${colors.gray[500]};
  padding-top: 32px;
  .disclaimer-text {
    max-width: 770px;
    ${font('text', 'xs', '400')}
    color: ${colors.gray[400]};
  }
`;
